<template>

  <b-modal :visible="props.show"
           title="Lesson Prompt"
           @close="$emit('close')"
           @hide="$emit('close')"
  >
    <b-overlay :visible="isProcessing">
      <div>
        <b-form-group label="Problem Statement">
          <div class="d-flex align-items-center">
            <b-form-select v-model="problemStatement"
                           :options="problemStatementOptions.map(option => ({ value: option.id, text: option.name }))"
                           :disabled="!problemStatementEnabled"
                           class="mr-2"
            />
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="problemStatementEnabled"
                switch
                class="mr-1"
              />
              <b-icon-info-circle
                v-b-tooltip.hover="'Enable/disable problem statement prompt for this lesson'"
                class="text-info cursor-pointer"
              />
            </div>
          </div>
        </b-form-group>
        <b-form-group label="Problem Feedback">
          <div class="d-flex align-items-center">
            <b-form-select v-model="problemFeedback"
                           :options="problemFeedbackOptions.map(option => ({ value: option.id, text: option.name }))"
                           :disabled="!problemFeedbackEnabled"
                           class="mr-2"
            />
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="problemFeedbackEnabled"
                switch
                class="mr-1"
              />
              <b-icon-info-circle
                v-b-tooltip.hover="'Enable/disable problem feedback prompt for this lesson'"
                class="text-info cursor-pointer"
              />
            </div>
          </div>
        </b-form-group>
        <b-form-group label="Problem Body">
          <div class="d-flex align-items-center">
            <b-form-select v-model="problemBody"
                           :options="problemBodyOptions.map(option => ({ value: option.id, text: option.name }))"
                           :disabled="!problemBodyEnabled"
                           class="mr-2"
            />
            <div class="d-flex align-items-center">
              <b-form-checkbox
                v-model="problemBodyEnabled"
                switch
                class="mr-1"
              />
              <b-icon-info-circle
                v-b-tooltip.hover="'Enable/disable problem body prompt for this lesson'"
                class="text-info cursor-pointer"
              />
            </div>
          </div>
        </b-form-group>
      </div>
    </b-overlay>
    <template #modal-footer>
      <b-button variant="secondary"
                @click="$emit('close')"
      >
        Close
      </b-button>
      <b-button v-if="props.lessonId"
                variant="primary"
                :disabled="isProcessing"
                @click="saveLessonPrompt"
      >
        <b-spinner v-show="isProcessing"
                   small
        />
        Submit
      </b-button>
    </template>
  </b-modal>
</template>

<script setup>
import {
  BModal, BFormGroup, BFormSelect, BOverlay, BButton, BSpinner, BFormCheckbox,
  BIconInfoCircle,
} from 'bootstrap-vue'
import { ref, getCurrentInstance, watch } from 'vue'
import useJwt from '@/auth/jwt/useJwt'

const props = defineProps({
  lessonId: {
    type: [Number, Object],
    default: () => null,
  },
  show: {
    type: Boolean,
    default: false,
  },
})

const root = getCurrentInstance().proxy.$root
const emit = defineEmits(['close'])
const isProcessing = ref(false)
const problemStatement = ref(null)
const problemFeedback = ref(null)
const problemBody = ref(null)

const problemStatementEnabled = ref(false)
const problemFeedbackEnabled = ref(false)
const problemBodyEnabled = ref(false)

const problemStatementOptions = ref([
  { id: null, name: 'Select a problem statement' },
])

const problemFeedbackOptions = ref([
  { id: null, name: 'Select feedback' },
])

const problemBodyOptions = ref([
  { id: null, name: 'Select a problem body' },
])

const getSaveParams = () => ({
  statement_prompt: problemStatement.value,
  statement_prompt_enabled: problemStatementEnabled.value,
  body_prompt: problemBody.value,
  body_prompt_enabled: problemBodyEnabled.value,
  feedback_prompt: problemFeedback.value,
  feedback_prompt_enabled: problemFeedbackEnabled.value,
})

const getAllPrompts = () => {
  isProcessing.value = true
  useJwt.getStudentLabPrompt().then(response => {
    const taskData = root.groupByKey(response.data.data.map(i => ({
      ...i,
      taskName: i.task?.name,
    })), 'taskName')
    problemStatementOptions.value = taskData['Problem Statement']
    problemBodyOptions.value = taskData['Problem Body 1']
    problemFeedbackOptions.value = taskData['Problem Feedback']
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
  })
}

const saveLessonPrompt = () => {
  isProcessing.value = true
  useJwt.saveLessonPrompt(props.lessonId, {
    ...getSaveParams(),
  }).then(response => {
    root.showSuccessMessage(response)
    emit('close')
  }).catch(error => {
    root.showErrorMessage(error)
  }).finally(() => {
    isProcessing.value = false
  })
}

const getLessonPrompt = () => {
  useJwt.getLessonPrompt(props.lessonId).then(response => {
    const { data } = response.data
    const statementPrompt = data.find(v => v.type === 'problem_statement')?.prompt_id
    const bodyPrompt = data.find(v => v.type === 'problem_body')?.prompt_id
    const feedbackPrompt = data.find(v => v.type === 'problem_feedback')?.prompt_id
    problemStatement.value = statementPrompt || null
    problemBody.value = bodyPrompt || null
    problemFeedback.value = feedbackPrompt || null
    problemStatementEnabled.value = !!data.find(v => v.type === 'problem_statement')?.is_active
    problemBodyEnabled.value = !!data.find(v => v.type === 'problem_body')?.is_active
    problemFeedbackEnabled.value = !!data.find(v => v.type === 'problem_feedback')?.is_active
  })
}

watch(() => props.show, value => {
  if (value) {
    getAllPrompts()
    if (props.lessonId) {
      getLessonPrompt()
    }
  }
})
</script>
